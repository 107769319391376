// App.js
import { useState } from "react";
import "./App.css";
import logo from "./assets/exportlogo.png"; // Adjust the path as necessary
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubscribe = () => {
    // Simple email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(email)) {
      toast.success(
        "You have been subscribed to the latest news from Vik Exports!"
      );
      setIsModalOpen(false);
      setEmail("");
    } else {
      toast.error("Please enter a valid email address.");
    }
  };

  return (
    <div className="App">
      <button className="subscribe-button" onClick={() => setIsModalOpen(true)}>
        Subscribe
      </button>
      <img src={logo} alt="Logo" className="logo" />

      <h1>VIK EXPORTS</h1>
      <p>Something exciting is coming soon. Stay tuned!</p>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>
              &times;
            </span>
            <h2>Subscribe to our Newsletter</h2>
            <div>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="inputbox"
              />
            </div>
            <button className="btn-primary" onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default App;
